import { navigateToPage } from '../../../core/routing/history';

const UDS_ROUTE = 'uds';
const BIDDERS_ROUTE = 'bidders';
const TARGETING = 'targeting';
const DEMAND_CONFIGURATION = 'demand-configuration';
const POLICIES = 'policies';
const POLICY = 'policy';

export const UDS_ROUTE_ROUTES = {
  UDS_ROUTE_BASE: `/${UDS_ROUTE}`,
  BIDDERS: `/${UDS_ROUTE}/${BIDDERS_ROUTE}`,
  TARGETING: `/${UDS_ROUTE}/${TARGETING}`,
  DEMAND_CONFIGURATION: `/${UDS_ROUTE}/${DEMAND_CONFIGURATION}`,
  POLICIES: `/${UDS_ROUTE}/${POLICIES}`,
  POLICY: `/${UDS_ROUTE}/${POLICY}`,
};

export const monetizationNavigator = {
  toBiddersPage() {
    navigateToPage(UDS_ROUTE_ROUTES.BIDDERS);
  },
  goToBidderEditPage: (id: string) => {
    navigateToPage(`${UDS_ROUTE_ROUTES.BIDDERS}/${id}`);
  },
  toTargetingPage() {
    navigateToPage(UDS_ROUTE_ROUTES.TARGETING);
  },
  toEditDemandConfigurationPage(publisherId: string) {
    navigateToPage(`${UDS_ROUTE_ROUTES.DEMAND_CONFIGURATION}/${publisherId}`);
  },
  toEditPoliciesPage(publuisherId: string, policyId: string) {
    navigateToPage(`${UDS_ROUTE_ROUTES.POLICIES}/${publuisherId}/${policyId}`);
  },
  toPoliciesPage() {
    navigateToPage(UDS_ROUTE_ROUTES.POLICIES);
  },
};
